import React from 'react';

import { JoinDiscordActionBlock, PartnerWithUsActionBlock, IndexSectionHeader } from '@features/home-page';
import { ReactComponent as AnnouncementIcon } from '@public/icons/announcement.svg';

export const ActionSection = (): JSX.Element => {
  return (
    <section className="flex flex-col gap-6">
      <IndexSectionHeader icon={<AnnouncementIcon />} header="What’s more..." />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex justify-center">
          <div className="max-w-[436px]">
            <JoinDiscordActionBlock />
          </div>
        </div>
        <div className="flex justify-center z-10">
          <div className="max-w-[436px]">
            <PartnerWithUsActionBlock />
          </div>
        </div>
      </div>
    </section>
  );
};
