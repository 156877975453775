import React from 'react';

import { ActionBlock } from '@features/home-page';

export const JoinDiscordActionBlock = (): JSX.Element => {
  return (
    <ActionBlock
      headerSection={
        <div className="bg-contain aspect-auto w-full">
          <img className="w-full h-full" src="/landing/discord_frame.svg" alt="discord-frame-image" />
        </div>
      }
      title="Join our Discord community"
      description="Become a member of our Discord community and take part in events and tournaments"
      href="https://discord.gg/8MXbvmgza2"
    />
  );
};
