import classnames from 'classnames';
import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { PlaydexLogoImage } from '@features/header';

import links from './footer-links';

type FooterProps = {
  isLinksHidden?: boolean;
  className?: string;
};

export const Footer = ({ isLinksHidden = false, className }: FooterProps): JSX.Element => {
  return (
    <footer
      className={classnames(
        'relative px-4 md:px-8 mt-16 pb-10',
        'flex flex-col gap-[25px] xl:flex-row xl:gap-14 xl:items-center',
        'font-sans text-sm text-white dark:text-white bg-transparent dark:bg-gray-700',
        className,
      )}
    >
      <div className="translate-x-[-7px] xl:translate-y-[-7px] self-start">
        <PlaydexLogoImage />
      </div>
      {!isLinksHidden && (
        <div className="grid grid-cols-2 gap-x-14 gap-y-8 md:flex md:flex-row lg:gap-20">
          {links.map(link => (
            <div key={link.group}>
              <h2 className="font-medium text-sm mb-4 text-labelPrimary">{link.group}</h2>
              <nav className="flex flex-col gap-4">
                {link.items.map((item, idx) =>
                  'href' in item ? (
                    <Link
                      className="whitespace-nowrap leading-5 text-sm text-labelSecondary hover:text-labelPrimary"
                      key={`link-${idx}`}
                      href={item.href}
                      target={item?.target}
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <span
                      className={cn(
                        'cursor-pointer whitespace-nowrap leading-5 text-sm',
                        'text-labelSecondary hover:text-labelPrimary',
                      )}
                      onClick={item.action}
                      key={`item-${idx}`}
                    >
                      {item.name}
                    </span>
                  ),
                )}
              </nav>
            </div>
          ))}
        </div>
      )}
      <div className="absolute bottom-4 right-4 z-20 ">
        <span className="text-xs text-labelTertiary">v. 23.4.5</span>
      </div>
    </footer>
  );
};
export default Footer;
