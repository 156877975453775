import cn from 'classnames';
import React from 'react';

import ElBluredImage from '@elements/ElBluredImage';
import { Button } from '@features/common';
import { ReactComponent as StarIcon } from '@public/landing/slides/stars_label_icon.svg';

export const ExploreGamesSection = (): JSX.Element => {
  return (
    <div
      className={cn(
        'relative text-white z-2 w-full bg-[#111218] rounded-3xl overflow-hidden',
        'px-6 md:px-[103px] pt-12 md:pt-[46px] pb-10 md:pb-8 mx-auto border-2 border-controlDefaultBorder',
        'mb-20',
      )}
    >
      <div
        className="bg-cover bg-center bg-no-repeat absolute inset-0 -z-1 pointer-events-none"
        style={{
          backgroundImage: 'url(/landing/slides/stars_bg_cover.svg)',
        }}
      />

      <main className="relative flex flex-col-reverse md:flex-row gap-x-20 justify-between md:max-w-[855px]">
        <StarIcon className="hidden md:block w-6.5 h-4 absolute -top-3 -left-4" />
        <section className="flex flex-col justify-center max-w-[470px] text-center md:text-left">
          <h1 className={cn('font-medium text-6 md:text-8 leading-10 text-labelPrimary z-20 mb-4')}>
            Play and Earn with the NFT Games on Playdex.io
          </h1>

          <p className="text-sm md:text-base text-labelSecondary mb-6">
            Experience the exciting world of NFT gaming and start earning today! Explore our curated collection of NFT
            games and enjoy a gaming adventure like no other.
          </p>
          <Button
            variant="primary"
            className="!cursor-pointer max-w-min whitespace-nowrap mx-auto md:mx-0 text-base md:text-sm !leading-6"
            href="/games"
          >
            Explore games
          </Button>
        </section>

        <section className="flex items-center">
          <ElBluredImage
            blur="2xl"
            alt="explore_game_playdex_icon"
            imgContainerClassName="w-[292px] h-[202px] md:w-[323px] md:h-[224px] mx-auto md:mx-0 relative"
            className="w-full h-full object-fill pointer-events-none"
            src="/landing/explore_game_playdex_icon.svg"
            secondBlurredImgSrc="/landing/explore_games_slide_blur_bg.svg"
          />
        </section>
      </main>
    </div>
  );
};
