import React from 'react';

import { ReactComponent as IconGradientDivider } from '@public/icons/gradient-divider.svg';

type IndexSectionHeaderProps = {
  icon?: JSX.Element;
  header: string;
};

export const IndexSectionHeader = ({ icon, header }: IndexSectionHeaderProps): JSX.Element => {
  return (
    <section className="flex items-center">
      <span className="flex-1 h-1 pr-4">
        <IconGradientDivider />
      </span>
      <span className="pr-2 flex items-center">
        {icon && <div className="w-7 h-7">{icon}</div>}
        <div className="text-xl font-medium pl-1">{header}</div>
      </span>
      <span className="flex-1 h-1 pl-4">
        <IconGradientDivider />
      </span>
    </section>
  );
};
