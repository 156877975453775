import classNames from 'classnames';

import { ReactComponent as Arrow } from '@public/icons/custom/chevron-down.svg';

export const CarouselArrowBtn = ({
  direction,
  clickHandler,
}: {
  direction: 'next' | 'prev';
  clickHandler: () => void;
}): JSX.Element => (
  <div
    onClick={clickHandler}
    className={classNames(
      'absolute top-1/4 z-50 flex items-center',
      'transition cursor-pointer opacity-50 hover:opacity-90 active:scale-[98%]',
      direction === 'prev' ? 'left-0' : 'right-0',
    )}
  >
    <div
      className={classNames('p-1.5 rounded-full flex items-center justify-center')}
      style={{
        backgroundColor: 'rgba(24, 25, 34, 0.8)',
      }}
    >
      {direction === 'prev' ? (
        <Arrow className="rotate-90 w-6 h-6 text-white" />
      ) : (
        <Arrow className="rotate-[270deg] w-6 h-6 text-white" />
      )}
    </div>
  </div>
);
