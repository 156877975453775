import React from 'react';

import { ActionBlock } from '@features/home-page';

export const PartnerWithUsActionBlock = (): JSX.Element => {
  return (
    <ActionBlock
      headerSection={
        <div className="bg-contain aspect-auto w-full">
          <img className="w-full h-full" src="/landing/partners_frame.svg" alt="" />
        </div>
      }
      title="Want to partner with us?"
      description="If you are a guild, web 3.0 game development company or streamer, feel free to contact us."
      href="https://playdex.typeform.com/partnershipform"
    />
  );
};
