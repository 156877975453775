import Link from 'next/link';
import React from 'react';

export const ButtonSeeAll = ({ href }: { href: string }): JSX.Element => {
  return (
    <div className="flex justify-center pt-3">
      <Link href={href} className="font-medium text-xs text-labelSecondary hover:text-white transition-colors">
        See all
      </Link>
    </div>
  );
};
