import React from 'react';

import { Button } from '@features/common';
import { BluredImageLink } from '@features/home-page/components/HeroSection/BluredImageLink';
import { PrismicRichTextWrapper } from '@features/prismic';
import { BannerSlideDocument } from '@globalTypes/prismic/documents';

export const BaseBannerSlide = ({
  bannerSlide: { data: bannerSlideData },
}: {
  bannerSlide: BannerSlideDocument;
}): JSX.Element | null => {
  const { description, title, logo, banner, actionButton, actionLink } = bannerSlideData;
  const { label: buttonLabel } = actionButton[0] || {};

  if (!banner?.url) {
    return null;
  }

  return (
    <div className="h-full p-0 md:p-4 pt-4 md:pt-0 flex flex-col md:flex-row justify-between md:gap-8">
      <section className="text-left flex flex-shrink flex-col justify-between mb-6 md:ml-4">
        {logo?.url && (
          <div className="hidden md:block mt-4">
            <img src={logo.url} alt="" className="max-w-[45px]" />
          </div>
        )}
        <div className="block md:hidden mx-auto md:max-w-[360px] mb-6">
          <BluredImageLink href={actionLink || '/'} imgSrc={banner?.url} />
        </div>
        <div>
          <div className="mb-4 text-2xl text-labelPrimary">{title}</div>
          <div className="mb-6 max-w-[550px] text-labelSecondary mx-auto md:mx-0">
            <PrismicRichTextWrapper field={description} />
          </div>
          {buttonLabel && actionLink && (
            <Button className="!py-1.5 !px-4" variant="secondary" href={actionLink || '/'}>
              {buttonLabel}
            </Button>
          )}
        </div>
      </section>
      <section className="hidden w-full md:flex flex-grow justify-end items-center">
        <BluredImageLink href={actionLink || '/'} imgSrc={banner?.url} />
      </section>
    </div>
  );
};
