import type { NextPage } from 'next';
import React from 'react';

import SimpleLayout from '@components/layouts/SimpleLayout';
import Footer from '@components/modules/Footer';
import { GamesAndTournamentsSidebar } from '@features/common';
import { gamesQueryDefaultParams, injectCommonStaticProps } from '@features/gamehub';
import {
  ButtonSeeAll,
  HorizontalGamesList,
  IndexSectionHeader,
  ActionSection,
  FaqSection,
  ExploreGamesSection,
  HeroSectionCarousel,
} from '@features/home-page';
import { fetchTournaments, TournamentsGrid } from '@features/tournaments';
import { BannerSlideDocument, FAQDocument, GameDocument } from '@globalTypes/prismic/documents';
import { TournamentFragmentFragment as TournamentFragment } from '@graphql/generated';
import { ReactComponent as IconGameControllerVariant } from '@public/icons/game-controller-variant.svg';
import { ReactComponent as IconGradientShield } from '@public/icons/gradient-shield.svg';
import { gameSliceSelector, tournamentSliceSelector } from '@store/slices';
import { useStore } from '@store/zustand';
import PageHead from '@templates/PageHead';

import { createClient } from '../prismicio';

type HomePageProps = {
  tournaments: TournamentFragment[] | null;
  tournamentGames: Record<string, GameDocument>;
  featuredGames: GameDocument[];
  trendingGames: GameDocument[];
  recentlyAddedGames: GameDocument[];
  faqList: FAQDocument['data']['faqList'];
  bannerSlides: Array<BannerSlideDocument>;
};

const HomePage: NextPage<HomePageProps> = ({
  bannerSlides,
  tournaments,
  tournamentGames,
  featuredGames,
  trendingGames,
  recentlyAddedGames,
  faqList,
}) => {
  const { sidebarGames } = useStore(gameSliceSelector);
  const { tournamentsPreview } = useStore(tournamentSliceSelector);

  return (
    <SimpleLayout sidebar={<GamesAndTournamentsSidebar tournaments={tournamentsPreview} games={sidebarGames} />}>
      <PageHead
        title="Playdex: Unlock the World of Next-Generation NFT Gaming!"
        description={
          'Welcome to Playdex, the premier NFT rental marketplace for the most sought-after play-to-earn games.' +
          'Explore our vast collection of top-tier titles and enjoy free gameplay' +
          " to your heart's content.  Join us now and experience the future of gaming!"
        }
      />
      <div className="px-4 md:px-9 md:pt-12 2xl:max-w-screen-xl 2xl:mx-auto">
        <HeroSectionCarousel bannerSlides={bannerSlides} />
        <IndexSectionHeader icon={<IconGradientShield />} header="Tournaments" />
        <ButtonSeeAll href="/tournaments" />
        <TournamentsGrid
          tournaments={tournaments}
          tournamentGames={tournamentGames}
          className="pt-8 pb-16 md:pb-32 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
          showGameTitle={true}
        />
        <ExploreGamesSection />
        <IndexSectionHeader icon={<IconGameControllerVariant />} header="Games" />
        <ButtonSeeAll href="/games" />
        <HorizontalGamesList games={featuredGames} header="Featured" className="pt-8" />
        <HorizontalGamesList games={trendingGames} header="Trending" className="pt-10" />
        <HorizontalGamesList games={recentlyAddedGames} header="Recently Added" className="pt-10" />
        <FaqSection faqList={faqList} />
        <div className="mt-20">
          <ActionSection />
        </div>
        <Footer />
      </div>
    </SimpleLayout>
  );
};

export const getStaticProps = injectCommonStaticProps<HomePageProps>(async () => {
  const prismicClient = createClient();
  const tournamentsPromise = fetchTournaments({ pagination: { limit: 3, offset: 0 } });

  const gamesPromise = prismicClient.getAllByType<GameDocument>('game', {
    ...gamesQueryDefaultParams,
    orderings: {
      field: 'document.first_publication_date',
      direction: 'desc',
    },
  });

  const bannerSlidesPromise = prismicClient.getAllByType<BannerSlideDocument>('bannerSlide', {
    orderings: [{ field: 'my.bannerSlide.order', direction: 'asc' }],
  });

  const faqPromise = prismicClient.getSingle<FAQDocument>('faq');

  const [tournaments, games, bannerSlides, faq] = await Promise.all([
    tournamentsPromise,
    gamesPromise,
    bannerSlidesPromise,
    faqPromise,
  ]);

  const sortedFaqList =
    faq?.data?.faqList?.sort(({ order: orderA }, { order: orderB }) => Number(orderA) - Number(orderB)) || [];

  const featuredGames = games.filter(game => game.data.isFeatured);
  const trendingGames = games.filter(game => game.data.isTrending);

  const tournamentGames = (tournaments ?? []).reduce((result, { id, gameUid, applicationName }) => {
    const game = games.find(game => game.uid === gameUid || game.data.applicationName === applicationName);

    return game ? { ...result, [id]: game } : result;
  }, {} as Record<string, GameDocument>);

  return {
    props: {
      tournaments: tournaments ?? null,
      tournamentGames,
      featuredGames,
      trendingGames,
      bannerSlides,
      faqList: sortedFaqList ?? [],
      recentlyAddedGames: games,
    },
  };
});

export default HomePage;
