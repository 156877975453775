import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import ElBluredImage from '@elements/ElBluredImage';

type BluredImageLinkProps = {
  href: string;
  imgSrc: string;
};

export const BluredImageLink = ({ href, imgSrc }: BluredImageLinkProps): JSX.Element => {
  return (
    <div className={cn('w-fit ')}>
      <Link href={href}>
        <ElBluredImage
          className=" md:max-w-initial w-full rounded-[8px]"
          secondBlurredImgClassName="blur-0"
          src={imgSrc}
          blur="sm"
          width={639}
          height={408}
        />
      </Link>
    </div>
  );
};
