import cn from 'classnames';
import Link from 'next/link';
import React, { useRef, useState } from 'react';

import styles from './ActionBlock.module.css';

type ActionBlockProps = {
  headerSection: JSX.Element;
  title: string;
  description: string;
  href: string;
};

export const ActionBlock = ({ headerSection, title, description, href }: ActionBlockProps): JSX.Element => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [cursorPosition, setCursorPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  return (
    <Link
      href={href}
      passHref={true}
      className={cn(
        'relative block !bg-bgSecondary rounded-[12px] border-1 border-bgStripe hover:border-bgActiveStripe',
        'transition transition-500 transition-ease',
        styles.searchlight,
      )}
      target="_blank"
      onMouseMove={e => {
        if (!ref.current) {
          return;
        }
        const rect = ref.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setCursorPosition({ x, y });
      }}
      style={
        {
          '--cursor-x': cursorPosition?.x,
          '--cursor-y': cursorPosition?.y,
        } as React.CSSProperties
      }
      ref={ref}
    >
      <div>
        <section>{headerSection}</section>
        <section className="px-6 pt-4 transition duration-300 pb-7">
          <div className="flex flex-col">
            <span className="mb-2 text-labelPrimary text-lg">{title}</span>
            <span className="text-labelSecondary text-sm">{description}</span>
          </div>
        </section>
      </div>
    </Link>
  );
};
