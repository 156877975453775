import cn from 'classnames';
import React from 'react';

import { LayoutMode } from '@features/common';
import { Header, HEADER_HEIGHT } from '@features/header';

type SimpleLayoutProps = {
  mode?: LayoutMode;
  className?: string;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
};

const SimpleLayout = ({
  children,
  footer = null,
  mode = 'default',
  sidebar,
  className,
}: SimpleLayoutProps): JSX.Element => {
  return (
    <div className={cn('h-screen flex flex-col bg-bgPrimary text-labelPrimary', className)}>
      <Header mode={mode} className="fixed top-0 left-0 right-0 z-[90] bg-bgPrimary" />
      <div
        className={cn('flex', sidebar && 'lg:grid grid-cols-[272px,1fr] h-full')}
        style={{
          paddingTop: HEADER_HEIGHT,
        }}
      >
        {sidebar && (
          <aside className="hidden lg:block h-full overflow-hidden shrink-0 border-r border-solid border-bgStripe">
            {sidebar}
          </aside>
        )}
        <div className="flex flex-col w-full h-full overflow-y-auto">
          <div className="flex-1">{children}</div>
          <div className="flex-shrink-0">{footer}</div>
        </div>
      </div>
    </div>
  );
};

export default SimpleLayout;
