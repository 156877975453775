import cn from 'classnames';
import React, { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { BaseBannerSlide } from '@features/home-page/components/HeroSection/BaseBannerSlide';
import { BannerSlideDocument } from '@globalTypes/prismic/documents';
import { useWindowSize } from '@hooks/useWindowSize';
import { CarouselArrowBtn } from '@templates/Home/Marketplaces/common/featuredAssets';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const HeroSectionCarousel = ({
  bannerSlides,
}: {
  bannerSlides: Array<BannerSlideDocument>;
}): JSX.Element => {
  const {
    orientation: { isMobile },
  } = useWindowSize();

  const slides = useMemo(
    () => [...bannerSlides.map((bannerSlide, index) => <BaseBannerSlide key={index} bannerSlide={bannerSlide} />)],
    [bannerSlides],
  );

  return (
    <div className="pb-10">
      <Carousel
        showThumbs={false}
        showArrows={isMobile}
        showStatus={false}
        showIndicators={true}
        infiniteLoop={true}
        autoPlay={true}
        swipeable={false}
        transitionTime={300}
        interval={8000}
        renderIndicator={(onClick, isSelected) => (
          <div
            onClick={onClick}
            className={cn(
              'hidden md:block w-10 h-1.5 rounded-md hover:bg-bgSecondary  transition duration-200 cursor-pointer',
              isSelected ? 'bg-[#3F415A]' : 'bg-bgTertiary',
            )}
          />
        )}
        renderArrowNext={clickHandler => (
          <div className="block md:hidden">
            <CarouselArrowBtn direction="next" clickHandler={clickHandler} />
          </div>
        )}
        renderArrowPrev={clickHandler => (
          <div className="block md:hidden">
            <CarouselArrowBtn direction="prev" clickHandler={clickHandler} />
          </div>
        )}
        className="hero-section-carousel"
      >
        {slides.map(
          (slide, index) =>
            slide && (
              <div className="md:h-[500px]" key={`slide_${index}`}>
                <div
                  className={cn(
                    'md:h-[460px] bg-bgPrimary',
                    'md:border-bgSecondary md:border-1',
                    'rounded-none md:rounded-3xl overflow-hidden',
                  )}
                >
                  {slide}
                </div>
              </div>
            ),
        )}
      </Carousel>
    </div>
  );
};
