import getConfig from 'next/config';
import { HTMLAttributeAnchorTarget } from 'react';

const { publicRuntimeConfig } = getConfig();
const { DISCORD_URL, TWITTER_URL, FACEBOOK_URL, LINKED_IN_URL, PLAYDEX_CAREER_LINK } = publicRuntimeConfig;

type StaticFooterLink = {
  href: string;
  name: string | JSX.Element;
  target?: HTMLAttributeAnchorTarget;
};

type DynamicFooterLink = {
  action: () => void;
  name: string | JSX.Element;
  target?: HTMLAttributeAnchorTarget;
};

type FooterLink = StaticFooterLink | DynamicFooterLink;

type FooterLinksGroup = {
  group: string;
  items: Array<FooterLink>;
};

type FooterLinks = Array<FooterLinksGroup>;

const footerLinks: FooterLinks = [
  {
    group: 'Product',
    items: [
      { href: '/games/kryptomon', name: 'Marketplace' },
      { href: '/games', name: 'Games' },
      { href: '/tournaments', name: 'Tournaments' },
      { href: '/blog', name: 'Blog' },
    ],
  },
  {
    group: 'Community',
    items: [
      { href: DISCORD_URL, name: 'Discord', target: '_blank' },
      { href: FACEBOOK_URL, name: 'Facebook', target: '_blank' },
      { href: TWITTER_URL, name: 'Twitter', target: '_blank' },
      { href: LINKED_IN_URL, name: 'Linkedin', target: '_blank' },
    ],
  },
  {
    group: 'Company',
    items: [
      { href: PLAYDEX_CAREER_LINK, name: 'Careers', target: '_blank' },
      { href: '/static/tos.html', name: 'Terms of Service', target: '_blank' },
      {
        href: '/static/privacy_policy.html',
        name: 'Privacy Policy',
        target: '_blank',
      },
    ],
  },
];

export default footerLinks;
