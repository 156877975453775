import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import { useUrlBuilder } from '@entities/urlBuilder';
import { GameStatusBadge, HorizontalScrollMenu, StatusBadge } from '@features/common';
import { GameDocument } from '@globalTypes/prismic/documents';
import { FeatureGroup } from '@services/featureChecker';
import { useFeatureChecker } from 'lib/context/featureChecker';

type GamesListProps = {
  header: string;
  games: GameDocument[];
  className?: string;
};

const GameItem = ({ game }: { game: GameDocument }): JSX.Element => {
  const urlBuilder = useUrlBuilder();

  const { isAvailable } = useFeatureChecker();

  const showIndexGenres = isAvailable(FeatureGroup.GameHub, 'SHOW_INDEX_GENRES');

  return (
    <Link href={urlBuilder.buildGameUrl({ gameId: game.uid })} className="text-white group">
      <img alt="" src={game.data?.cover?.url ?? ''} className="w-full object-cover min-w-full aspect-[250/120]" />
      <div className="bg-bgSecondary p-4 h-full group-hover:bg-bgTertiary">
        <div className="flex items-center">
          <span className="text-base font-medium text-wrap-1 flex-1">{game.data.name}</span>
          <span className="text-xs">
            <GameStatusBadge status={game.data.status} />
          </span>
        </div>

        {showIndexGenres && game.data?.genres?.length ? (
          <div className="flex flex-wrap gap-2 pt-3">
            {game.data?.genres?.map((e, i) => (
              <StatusBadge key={i} className="bg-bgTertiary rounded text-xs px-1.5 py-1">
                {e.genre?.data?.name ?? ''}
              </StatusBadge>
            ))}
          </div>
        ) : null}
      </div>
    </Link>
  );
};

export const HorizontalGamesList = ({ header, games, className }: GamesListProps): JSX.Element => {
  return (
    <div className={className}>
      <HorizontalScrollMenu
        itemClassName={classNames(
          'w-55 xl:w-75 2xl:w-[calc(25%_-_1rem)]',
          'flex-shrink-0 mr-4 rounded-lg overflow-clip border-gradient-bg-secondary',
        )}
        header={header}
      >
        {games.map(game => (
          <GameItem game={game} key={game.id} />
        ))}
      </HorizontalScrollMenu>
    </div>
  );
};
