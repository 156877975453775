import React from 'react';

import { DisclosurePanel } from '@features/common';
import { PrismicRichTextWrapper } from '@features/prismic';
import { FAQDocument } from '@globalTypes/prismic/documents';

type FAQSectionProps = { faqList: FAQDocument['data']['faqList'] };

export const FaqSection = ({ faqList }: FAQSectionProps): JSX.Element | null => {
  if (!faqList || faqList.length === 0) {
    return null;
  }

  return (
    <div className="mt-20">
      <div className="text-center mb-6">
        <h3 className="text-labelPrimary text-xl font-medium mb-2">FAQs</h3>
        <span className="text-labelSecondary text-sm">Answers to the most frequently asked questions</span>
      </div>
      <section className="border-1 border-controlDefaultBorder rounded-3 overflow-hidden max-w-[800px] mx-auto">
        {faqList.map((faq, index) => (
          <DisclosurePanel
            key={index}
            defaultOpen={faq.isDefaultOpen}
            className="bg-dashboardSecondaryBg"
            triggerClassName="!px-4 !py-5 !rounded-none"
            panelClassName="px-4 pt-0"
            trigger={<h3>{faq.question}</h3>}
            withAnimation={false}
          >
            <PrismicRichTextWrapper field={faq.answer} />
          </DisclosurePanel>
        ))}
      </section>
    </div>
  );
};
